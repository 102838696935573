#breadcrumbs{
	font-size:16px;
	margin:20px 0px;
	
	.space{
		display: inline-block;
		width: 10px;
	}
	
	ul{
		padding-left:0px;
		li{
			display:inline-block;
			list-style: none;
			
			&.hyphen{
				padding:0px 5px;
			}
			
			a{
				text-decoration:none;
			}
		}
	}
}